import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'qs';
import { useReducer, useState } from 'react';
import CustomerDetailsReducer from '../../../reducers/customer-support/customer-details/CustomerDetailsReducer';
import { LOADING_STARTS, LOADING_STOPS } from '../../../constants/types/shared/LoadingTypes';
import { GET_CUSTOMER_REVENUE } from '../../../constants/types/business-development/customer-revenue/CustomerRevenueTypes';
import {
  GET_CUSTOMER_DETAILS_URL,
  GET_UPDATE_CUSTOMER_DETAILS_URL,
  KYC_UPLOAD_URL,
} from '../../../services/api/customer-support/customer-details/CustomerDetailsUrl';

const useCustomerDetails = () => {
  const [{ items, total, pages, isLoading }, dispatch] = useReducer(CustomerDetailsReducer, {
    items: [],
    total: '',
    pages: '20',
    isLoading: false,
  });

  const getCustomerDetails = async (data, selectedPage) => {
    dispatch({ type: LOADING_STARTS });
    try {
      const response = await axios.post(`${GET_CUSTOMER_DETAILS_URL}/${selectedPage}`, data, {
        headers: { 
          Authorization: `Bearer ${sessionStorage.getItem('token')}` 
        } 
      });

      if (response.data) {
        console.log(response.data.total);
        dispatch({
          type: GET_CUSTOMER_REVENUE,
          payload: {
            total: response.data.count,
            items: response.data.rows,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: LOADING_STOPS });
      }
    }
  };

  const UpdateCustomerDetailsSharedStatus = async (data) => {
    dispatch({ type: LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${GET_UPDATE_CUSTOMER_DETAILS_URL}`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      },
    };
    await axios(config)
      .then((response) => {
        if (response.data[0] === 1) {
          // console.log('true');

          toast.success('Update Successful');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          toast.info('Something went wrong!');
          dispatch({ type: LOADING_STOPS });
        }
      });
  };

  const KycUpload = async (data) => {
    dispatch({ type: LOADING_STARTS });
    const config = {
      method: 'post',
      url: `${KYC_UPLOAD_URL}`,
      data,
      headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    };

    await axios(config)
      .then((response) => {
        if (response.data) {
          // console.log('true');

          toast.success('Upload Successful');
          setTimeout(() => {
            window.location.assign('https://os.tizeti.com/login#/new-design/installations');
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          toast.info('Something went wrong!');
          dispatch({ type: LOADING_STOPS });
        }
      });
  };

  return {
    getCustomerDetails,
    KycUpload,
    UpdateCustomerDetailsSharedStatus,
    items,
    total,
    pages,
    isLoading,
  };
};

export default useCustomerDetails;
