import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import * as helpers from './helpers';
import * as utilHelpers from '../../../../../utils/helpers';
import moment from 'moment';

const columns = [
  { id: 'serial', label: 'Serial Number', minWidth: 150 },
  { id: 'name', label: 'Name', minWidth: 450 },
  { id: 'mail', label: 'Email', minWidth: 100 },
  //   { id: 'email', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'username',
    label: 'Mac_id',
    minWidth: 150,
    align: 'right',
    // format: (value) => value.toLocaleString('en-US'),
  },
  // {
  //   id: 'acctstarttime',
  //   label: 'Start Time',
  //   minWidth: 220,
  //   align: 'right',
  //   // format: (value) => value.toLocaleString('en-US'),
  // },
  {
    id: 'status',
    label: 'Status',
    minWidth: 150,
    align: 'right',
  },
  // {
  //   id: 'downtime',
  //   label: 'Downtime\u00a0(hrs)',
  //   minWidth: 100,
  //   align: 'right',
  //   format: (value) => value.toLocaleString('en-US'),
  // },
  // {
  //   id: 'download_gb',
  //   label: 'Download\u00a0(GB)',
  //   minWidth: 100,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
  // {
  //   id: 'upload_gb',
  //   label: 'upload\u00a0(GB)',
  //   minWidth: 100,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
];

export default function ColumnGroupingTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [mac_id, setMacId] = React.useState('');
  console.log('🚀 ~ ColumnGroupingTable ~ mac_id:', mac_id);
  const [totalPages, setTotalPages] = React.useState(0);
  const [MAC_type, setMAC_type] = React.useState('Non-LTE');

  const handleChangePage = (event, newPage) => {
    console.log('🚀 ~ handleChangePage ~ newPage:', newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setPage(0);
  };

  async function getCustomerDowntime() {
    setIsLoading(true);
    try {
      const res = await axios.get(`https://api.tizeti.com/api/v1/users/getUsersNetworkStatus?mac_id=${mac_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res.data && res.data.status) {
        console.log('Customer Downtime', res.data.data);
        //   add the serial number to the data
        const formattedData = res.data.data.map((data, index) => ({
          ...data,
          serial: index + 1,
          // acctstarttime: data.acctstarttime ? moment.utc(data.acctstarttime).format('DD-MM-YYYY h:mm:ss a') : 'N/A',
        }));
        setRows(formattedData);
        setTotalPages(res.data.total_records);
        setIsLoading(false);
      }
    } catch (error) {
      console.log('An error occurred', error);
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    getCustomerDowntime();
  }, [page]);

  const handleSpaceBar = (e) => {
    if (e.keyCode === 32) e.preventDefault();
  };
  const handleChange = (e) => {
    const { value } = e.target;
    if (MAC_type === 'Non-LTE') return setMacId(helpers.NonLTEInput(value, mac_id));
    return setMacId(helpers.LTEInput(value));
  };

  return (
    <Paper sx={{ maxWidth: '95%', overflowX: 'auto', px: '2rem', pb: '4rem' }}>
      <Box sx={{ mt: '6rem' }}>
        <Typography variant="body" color="#666666">
          Radio MAC type:
        </Typography>
        <div className="flex flex-row gap-3 mt-[15px]">
          <input
            type="radio"
            name="MAC_type"
            id="LTE"
            value="LTE"
            checked={MAC_type === 'LTE'}
            onChange={(e) => {
              setMAC_type(e.target.value);
              setMacId('');
            }}
          />{' '}
          LTE
          <input
            type="radio"
            name="MAC_type"
            id="Non-LTE"
            value="Non-LTE"
            checked={MAC_type === 'Non-LTE'}
            onChange={(e) => {
              setMAC_type(e.target.value);
              setMacId('');
            }}
          />{' '}
          Non-LTE
        </div>
        <div className="flex">
          <input
            type="text"
            className="w-[90%] h-10 p-2 mt-[15px] rounded border-solid border "
            placeholder={MAC_type === 'Non-LTE' ? 'xx:xx:xx:xx:xx:xx' : 'xxxxxxxxxxxxxxx'}
            required
            name="mac_id"
            value={mac_id}
            onKeyDown={handleSpaceBar}
            onChange={handleChange}
            maxLength={MAC_type === 'Non-LTE' ? 17 : 15}
            pattern={
              MAC_type === 'Non-LTE'
                ? '[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}'
                : '[0-9]{15}'
            }
            title={
              MAC_type === 'Non-LTE'
                ? 'Please enter a valid Non-LTE MAC ID (XX:XX:XX:XX:XX:XX)'
                : 'Please enter a valid 15-digit LTE MAC ID (XXXXXXXXXXXXXXX)'
            }
            disabled={isLoading}
          />
          <button
            className="btn btn-green py-2 px-5 ml-4"
            disabled={
              isLoading ||
              (MAC_type === 'Non-LTE' && mac_id.length !== 17) ||
              (MAC_type === 'LTE' && mac_id.length !== 15)
            }
            onMouseDown={(e) => {
              e.preventDefault();
              getCustomerDowntime();
            }}
          >
            {isLoading ? 'Searching...' : 'Search'}
          </button>
          {/* {((MAC_type === 'Non-LTE' && mac_id.length === 17) || (MAC_type === 'LTE' && mac_id.length === 15)) && (
            <button
              className="btn btn-red py-2 px-5 ml-4"
              disabled={isLoading}
              onMouseDown={(e) => {
                e.preventDefault();
                setMacId('');
                getCustomerDowntime();
              }}
            >
              Clear Search
            </button>
          )} */}
        </div>
      </Box>
      <TableContainer sx={{ maxHeight: 540, px: '1rem' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            'loading table...'
          ) : (
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.username + '-' + row.acctstarttime}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'status' ? (
                            <Chip label={value} variant="outlined" color={value === 'online' ? 'success' : 'error'} />
                          ) : column.format && typeof value === 'number' ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box sx={{ px: '1rem' }}>
        <TablePagination
          rowsPerPageOptions={totalPages}
          component="div"
          count={totalPages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Paper>
  );
}
