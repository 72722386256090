export let cvLength = 0;
export const NonLTEInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\w]/g, '').toUpperCase();
  cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 3) return currentValue;
    if (cvLength < 5) return `${currentValue.slice(0, 2)}:${currentValue.slice(2)}`;
    if (cvLength < 7) return `${currentValue.slice(0, 2)}:${currentValue.slice(2, 4)}:${currentValue.slice(4, 6)}`;
    if (cvLength < 9)
      return `${currentValue.slice(0, 2)}:${currentValue.slice(2, 4)}:${currentValue.slice(4, 6)}:${currentValue.slice(
        6,
      )}`;
    if (cvLength < 11)
      return `${currentValue.slice(0, 2)}:${currentValue.slice(2, 4)}:${currentValue.slice(4, 6)}:${currentValue.slice(
        6,
        8,
      )}:${currentValue.slice(8)}`;
    return `${currentValue.slice(0, 2)}:${currentValue.slice(2, 4)}:${currentValue.slice(4, 6)}:${currentValue.slice(
      6,
      8,
    )}:${currentValue.slice(8, 10)}:${currentValue.slice(10, 12)}`;
  }
};

export const LTEInput = (value) => {
  if (!value) return value;
  return value.replace(/[^\d]/g, '').slice(0, 15);
};
