import React from 'react';
import FieldSupportSideBar from '../../../../layouts/field-support/side-bar/FieldSupportSideBar';
import { Link } from 'react-router-dom';
import Logout from '../../../shared/auth/Logout';
import PageName from '../../../shared/layouts/PageName';
import CustomerDowntimeTable from './CustomerDowntimeTable';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';

export default function CustomerDowntimeOverview() {
  const { profile, user } = useUser();
  const { firstName } = profile;

  return (
    <div>
      <main className="s-layout__content col-lg-10 p-0">
        <FieldSupportNavBar
          currentPage="Dedicated Customer Network Status"
          roleID={user.role_id}
          position={firstName}
        />
        {/* <div style={{ width: '100%' }}> */}
        <CustomerDowntimeTable />
        {/* </div> */}
      </main>
    </div>
  );
}
