/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

import show from '../../../../../../src/assets/img/icons/view.png';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import loader from '../../../../../../src/assets/img/icons/TizetiLoaderColored.gif';
import helpers from '../../../../../utils/helpers';
import { Modal } from 'react-bootstrap';

const AccountsFirsTransactionsTable = ({
  lists,
  isLoading,
  isReverseLoading,
  reverseTransaction,
  reverseFile,
  reverseFullTransaction,
  isFullReverseLoading,
}) => {
  const [view, setView] = useState(false);
  const [trans, setTrans] = useState({ id: null, amount: null, trans_type: null });
  const [showFullReversal, setShowFullReversal] = useState(false);
  const [fullReversalTrans, setFullReversalTrans] = useState(
    {
      reference: null,
      email: null,
      trans_type: null,
      id: null
    }
  );
  const tableData =
    lists && lists.length > 0 ? (
      lists
        .map((item, i) => {
          return (
            <tr className="text-nowrap" key={i}>
              <td>{i + 1}</td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.companyProductID}</td>
              <td>{item.companyProductName}</td>
              <td>{item.actualAmount.toFixed(1)}</td>
              <td>{helpers.formatDateAndTimes(item.date)}</td>
              <td className="d-flex justify-content-center">
                <button
                  disabled={item.reversal_status}
                  className="view btn btn-light btn-sm"
                  style={{ cursor: item.reversal_status ? 'not-allowed' : 'pointer' }}
                  onClick={() => {
                    setView(true);
                    setTrans({ id: item.id, amount: item.actualAmount, trans_type: item.companyProductName });
                  }}
                >
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1543579893/edit.png"
                    data-toggle="tooltip"
                    data-placement="right"
                    alt="delete"
                    title="Delete"
                  />
                </button>
              </td>
              <td>
                <button
                  disabled={item.reversal_status}
                  className="view btn btn-light btn-sm"
                  style={{ cursor: item.reversal_status ? 'not-allowed' : 'pointer' }}
                  onClick={() => {
                    setShowFullReversal(true);
                    setFullReversalTrans({ reference: item.reference, email: item.email, trans_type: item.companyProductName, id: item.id });
                  }}
                >
                  <span className="view2">
                    <img src={show} alt="view" data-toggle="tooltip" data-placement="left" title="View" />
                  </span>
                </button>
              </td>
              <td>
                {item.reversal_status ? (
                  <span className="p-1 badge badge-pill badge-info text-sm">reversed</span>
                ) : (
                  <span className="p-1 badge badge-pill badge-success text-sm">posted</span>
                )}
              </td>
              <td>{item.retrunMessage || 'N/A'}</td>
            </tr>
          );
        })
        .reverse()
    ) : (
      <NoItemRow colSpan={7} text="There are no records" />
    );
  return (
    <div className="table-responsive content col-13 px-1">
      <table className="table">
        <thead>
          <tr className="">
            <th>#</th>
            <th className="text-nowrap">Customer Name</th>
            <th className="text-nowrap">Customer Emailh</th>
            <th className="text-nowrap">Product ID</th>
            <th className="text-nowrap">Product Name</th>
            <th> Amount</th>
            <th className="text-nowrap"> Date Created</th>
            <th className="text-nowrap"> Partial Reversal </th>
            <th className="text-nowrap"> Full Reversal</th>
            <th className="text-nowrap"> Reversal Status</th>
            <th className="text-nowrap"> Return Message</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td className="text-center">
                <img src={loader} alt="loading gif" />
              </td>
            </tr>
          ) : (
            tableData
          )}
        </tbody>
      </table>
      <ReverseTransaction
        view={view}
        setView={setView}
        isReverseLoading={isReverseLoading}
        reverseTransaction={reverseTransaction}
        trans={trans}
        reverseFile={reverseFile}
      />
      <FullReverseTransaction
        showFullReversal={showFullReversal}
        setShowFullReversal={setShowFullReversal}
        isFullReverseLoading={isFullReverseLoading}
        reverseFullTransaction={reverseFullTransaction}
        fullReversalTrans={fullReversalTrans}
      />
    </div>
  );
};

export default AccountsFirsTransactionsTable;

const ReverseTransaction = ({ view, setView, isReverseLoading, reverseTransaction, trans, reverseFile }) => {
  const [amount, setAmount] = useState(null);
  const handleClose = () => {
    setView(false);
  };
  return (
    <Modal show={view} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Reverse Transaction</Modal.Title>
      </Modal.Header>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          reverseTransaction(trans.id, amount, reverseFile);
        }}
        className="col-md-12"
      >
        <h5 className="text-center mt-3">
          Click on button bellow to Reverse this <strong>{trans.trans_type}</strong> Transaction
        </h5>

        <div className="col-12 mb-3 mt-3 ">
          <h6 className="copy-font ml-0"> Amount</h6>
          <input
            name="amount"
            id="amount"
            type="number"
            className="form-control mx-auto border"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            required
          />
        </div>

        <div className="text-center my-3">
          {isReverseLoading ? (
            <button type="button" className="btn btn-green py-2 px-5 p-28" disabled>
              Confirming...
            </button>
          ) : (
            <button type="submit" className="btn btn-green py-2 px-5 p-2">
              Confirm
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};

const FullReverseTransaction = ({
  showFullReversal,
  setShowFullReversal,
  isFullReverseLoading,
  reverseFullTransaction,
  fullReversalTrans,
}) => {
  const handleClose = () => {
    setShowFullReversal(false);
  };
  return (
    <Modal show={showFullReversal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Reverse Transaction Fully</Modal.Title>
      </Modal.Header>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          reverseFullTransaction(fullReversalTrans.reference, fullReversalTrans.email, fullReversalTrans.id);
        }}
        className="col-md-12"
      >
        <h5 className="text-center mt-3">
          Are you sure you want to fully reverse this <strong>{fullReversalTrans.trans_type}</strong> Transaction
        </h5>

        <div className="text-center my-3">
          {isFullReverseLoading ? (
            <button type="button" className="btn btn-green py-2 px-5 p-28" disabled>
              Confirming...
            </button>
          ) : (
            <button type="submit" className="btn btn-green py-2 px-5 p-2">
              Confirm
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};
